<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import appConfig from "../../../../app.config";

import DashboardSidebar from "./DashboardSidebar.vue";
import DashboardMenuMain from "./DashboardMenuMain.vue";
import DashboardMenuEpisodes from "./DashboardMenuEpisodes.vue";
import DashboardMenuPodcasts from "./DashboardMenuPodcasts.vue";

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Dashboard",
      menu: 'main',
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    DashboardSidebar,
    DashboardMenuMain,
    DashboardMenuEpisodes,
    DashboardMenuPodcasts
  },
  created: function () {
  },
  mounted() {
    this.$refs.mainPanel.loadingData();
  },
  methods: {
    sidebarMenuChanged: function(e) {
      this.menu = e;
      this.$nextTick(() => {
        switch (this.menu) {
          case 'podcasts':
            this.$refs.podcastsPanel.loadingData();
            break;
          case 'episodes':
            this.$refs.episodesPanel.loadingData();
            break;
          case 'main':
          default:
            this.$refs.mainPanel.loadingData();
            break;
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #sidebar>
        <DashboardSidebar @changed-sidebar-menu="sidebarMenuChanged"></DashboardSidebar>
      </template>
      <template #content>
        <DashboardMenuMain ref="mainPanel" v-if="menu==='main'"></DashboardMenuMain>
        <DashboardMenuPodcasts ref="podcastsPanel" v-if="menu==='podcasts'"></DashboardMenuPodcasts>
        <DashboardMenuEpisodes ref="episodesPanel" v-if="menu==='episodes'"></DashboardMenuEpisodes>
      </template>
    </Container>
  </Layout>
</template>
